import { Dialog, Transition } from '@headlessui/react'
import { ComponentProps, ElementType, Fragment, PropsWithChildren } from 'react'

import Button from '@/modules/shared/components/button/Button'
import { CloseIcon } from '@/modules/shared/icons/CloseIcon'
import { classNames } from '@/modules/shared/utils/classNames'

export interface ModalProps extends PropsWithChildren {
  showModal: boolean
  onCloseModal: () => void
  dataTestId?: string
}

function ModalComponent(props: ModalProps) {
  const { showModal, onCloseModal, dataTestId, children } = props
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-40" unmount onClose={onCloseModal} data-testid={dataTestId}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface ModalTitleProps {
  title: string
  onCloseModal?: () => void
  testId?: string
  className?: string
  as?: ElementType
}

function ModalTitle({ title, onCloseModal, testId, className, as = 'h2' }: ModalTitleProps) {
  return (
    <Dialog.Title
      className={classNames('flex w-full items-center justify-between border-b px-5 py-3', className)}
      data-testid={testId}
      as={as}
    >
      <span className="text-gray-800">{title}</span>
      {onCloseModal && (
        <Button type="button" className="h-11 rounded-md bg-gray-100 px-3 focus:outline-none" onClick={onCloseModal}>
          <CloseIcon className="size-5" />
        </Button>
      )}
    </Dialog.Title>
  )
}

interface ModalFooterProps extends ComponentProps<'footer'> {
  className?: string
}

function ModalFooter({ children, className, ...restProps }: ModalFooterProps) {
  return (
    <footer {...restProps} className={classNames('w-full border-t px-5 py-3', className)}>
      {children}
    </footer>
  )
}

interface ModalBodyProps extends ComponentProps<'section'> {
  className?: string
}

function ModalBody({ children, className, ...restProps }: ModalBodyProps) {
  return (
    <section {...restProps} className={classNames('flex-1 p-5 text-sm overflow-y-overlay', className)}>
      {children}
    </section>
  )
}

export const Modal = Object.assign(ModalComponent, {
  Panel: Dialog.Panel,
  Title: ModalTitle,
  Footer: ModalFooter,
  Body: ModalBody,
  Description: Dialog.Description,
})
