import i18next from 'i18next'

import { Maybe } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DEFAULT_CONCATENATED_SELL_UNIT } from '@/modules/requisitions/constants'
import { titleCase } from '@/modules/shared/utils/titleCase'

export type Input = {
  itemSize: Maybe<number>
  itemMeasure: Maybe<string>
  itemSellUnit: Maybe<string>
  itemPackName: Maybe<string>
  itemSellQuantity: Maybe<number>
}

/**
 * Formats product item details into a readable string.
 * @param input - The product item properties.
 * @returns Formatted product item string.
 */
export function formatProductItem(input: Input): string {
  if (import.meta.env.VITE_APP_FORMAT_ITEM_NEW === 'true') {
    return newFormatProductAndStockItem(input.itemSellQuantity)
  } else {
    let { itemMeasure, itemPackName, itemSellUnit } = input
    const { itemSize } = input
    let result = ''

    const itemSizeString = itemSize ?? ''
    itemMeasure = itemMeasure ?? ''
    itemPackName = itemPackName ?? ''
    itemSellUnit = itemSellUnit ?? ''

    if (itemSize === 1 && itemMeasure === 'each' && itemSellUnit === DEFAULT_CONCATENATED_SELL_UNIT && !itemPackName) {
      result = titleCase(DEFAULT_CONCATENATED_SELL_UNIT)
    } else {
      result = `${itemSizeString} ${itemMeasure} ${titleCase(itemPackName || '')} ${
        (itemSize || itemMeasure || itemPackName) && '|'
      } ${titleCase(itemSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}`
    }
    return result.replace(/\s+/g, ' ').trim()
  }
}

/**
 * Formats stock item details into a readable string
 * @param input - The stock item properties
 * @returns Formatted stock item string
 */
export function formatStockItem(input: Omit<Input, 'itemSellUnit'>): string {
  if (import.meta.env.VITE_APP_FORMAT_ITEM_NEW === 'true') {
    return newFormatProductAndStockItem(input.itemSellQuantity)
  } else {
    let { itemMeasure, itemPackName } = input
    const { itemSize } = input
    let result = ''

    const itemSizeString = itemSize ?? ''
    itemMeasure = itemMeasure ?? ''
    itemPackName = itemPackName ?? ''

    if (itemSize === 1 && itemMeasure === 'each') {
      result = titleCase(DEFAULT_CONCATENATED_SELL_UNIT)
    } else {
      result = `${itemSizeString} ${itemMeasure} ${titleCase(itemPackName || '')}`
    }

    return result.replace(/\s+/g, ' ').trim()
  }
}

export function newFormatProductAndStockItem(itemSellQuantity: number | null) {
  const quantity = itemSellQuantity || 0

  return i18next.t('newProductSize.size_plural', {
    count: quantity,
    defaultValue_one: '{{count}} x Unit',
    defaultValue_many: '{{count}} x Units',
    defaultValue_other: '{{count}} x Units',
  })
}

export function formatStockItemSellUnit(
  input: Pick<Input, 'itemSellQuantity'> & { itemSellPackName: Maybe<string> }
): string {
  let { itemSellPackName } = input
  const { itemSellQuantity } = input
  let result = ''

  const itemSellQuantityString = itemSellQuantity ?? ''
  itemSellPackName = itemSellPackName ?? ''

  if (itemSellQuantity === 1 && itemSellPackName === 'each') {
    result = titleCase(DEFAULT_CONCATENATED_SELL_UNIT)
  } else {
    result = `${itemSellPackName} of ${itemSellQuantityString}`
  }

  return result.replace(/\s+/g, ' ').trim()
}
