import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusFeatureFlagsFragmentDoc } from './FeatureFlags.generated'
import * as Types from './purchasePlus_graphql'

export const GetSessionUserDocument = gql`
  query GetSessionUser {
    backendVersion
    currentAccountsPayableSystem: accountsPayableSystem {
      id
      name
      currency
    }
    currentUser {
      id
      email
      fullName
      isAdmin
      currentSignInAt
      autoSendPurchaseOrder
      accessControlList
      tandcagreedon
      defaultOrganisation {
        id
        name
      }
      applicationPreferences {
        viewPreference
        allowInvoiceEditing
        expertMode
        defaultLanguage
        shoppingPreference
      }
    }
    currentHolder {
      id
      purchaser {
        id
        name
      }
    }
    currentOrganisationUnit {
      id
      name
      ppxAccess
      legalEntity
      createdAt
      isAPurchaser
      isASupplier
      parent {
        id
        name
        parent {
          id
          name
        }
      }
      featureFlags {
        ...PurchasePlusFeatureFlags
      }
      ... on Purchaser {
        currency
        defaultAccount {
          id
          name
          codedName
        }
        paperlessInvoicingIdentityEmail
        logoUrl
        signatureUrl
      }
      ... on Supplier {
        defaultCurrency
      }
    }
    currentLegalEntity {
      ... on Purchaser {
        id
        name
        isASupplier
        isAPurchaser
        currency
        ppnewAccess
        isInventoryEnabled
      }
      ... on Supplier {
        id
        name
        isASupplier
        isAPurchaser
        ppnewAccess
      }
    }
  }
  ${PurchasePlusFeatureFlagsFragmentDoc}
` as unknown as DocumentNode<Types.GetSessionUserQuery, Types.GetSessionUserQueryVariables>
