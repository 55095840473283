import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GetOrganisationFlatAccountsDocument } from '@/graphql/purchase-plus/generated/getOrganisationFlatAccounts.generated'
import { Account, Purchaser } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useGetAccountsPayableSystemFlatAccounts from '@/modules/admin-center/hooks/useGetAccountsPayableSystemFlatAccounts'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import useOrganisationId from '@/modules/shared/hooks/params/useOrganisationId'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

interface UseGetOrganisationFlatAccountsProps {
  organisationId?: number
  lazyQuery?: boolean
  isParent?: boolean
}

// When no accounts are returned from the original query, all accounts are "disabled" and therefore we consider all accounts from the AP System to be available instead.
// This hook will return the correct list of accounts based off of that logic.
export default function useGetOrganisationFlatAccounts(args?: UseGetOrganisationFlatAccountsProps) {
  const { organisationId: organisationIdProp, lazyQuery = false, isParent } = args || {}
  const paginationResultsPerPage = 20
  const organisationId = useOrganisationId()
  const { searchValue, accountType } = useWatchQueryParams()
  const [fetchOrganisationFlatAccounts, { data, networkStatus, error, fetchMore, refetch }] = useLazyQuery(
    GetOrganisationFlatAccountsDocument,
    {
      variables: {
        organisationId: Number(organisationIdProp || organisationId),
        filters: {
          searchText: searchValue,
          hasParent: accountType === null ? undefined : accountType,
          isParent,
        },
      },
      context: { uri: PURCHASE_PLUS_GRAPHQL_API },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  )
  const [apSystemId, setApSystemId] = useState<number | null>(null)

  const organisation = data?.organisation as Purchaser
  const allAccountsDisabled = organisation?.allAccountsDisabled
  const accountsPayableSystemId = organisation?.accountsPayableSystem?.id
  const pageInfo = organisation?.flatAccountCodes?.pageInfo

  const {
    fetchAccountsPayableSystemFlatAccounts,
    flatAccounts: apSystemFlatAccounts,
    error: apSystemError,
    onFetchMoreFlatAccounts: onFetchMoreApSystemFlatAccounts,
    pageInfo: apSystemPageInfo,
    networkStatus: apSystemNetworkStatus,
    refetch: refetchApSystemFlatAccounts,
  } = useGetAccountsPayableSystemFlatAccounts({
    lazyQuery: true,
    accountsPayableSystemId: Number(apSystemId),
    isParent,
  })

  useEffect(() => {
    if (allAccountsDisabled && apSystemId) {
      fetchAccountsPayableSystemFlatAccounts()
    }
  }, [allAccountsDisabled, apSystemId])

  useEffect(() => {
    if (!lazyQuery) {
      fetchOrganisationFlatAccounts()
    }
  }, [lazyQuery])

  useEffect(() => {
    if (accountsPayableSystemId) {
      setApSystemId(accountsPayableSystemId)
    }
  }, [accountsPayableSystemId])

  return {
    fetchOrganisationFlatAccounts,
    flatAccounts: allAccountsDisabled ? apSystemFlatAccounts : extractEdges<Account>(organisation?.flatAccountCodes),
    allAccountsDisabled,
    accountsPayableSystemId: organisation?.accountsPayableSystem?.id,
    loadingStates: checkNetworkStatus(allAccountsDisabled ? apSystemNetworkStatus : networkStatus),
    error: allAccountsDisabled ? apSystemError : error,
    onFetchMoreFlatAccounts: () =>
      onFetchMore({
        endCursor: allAccountsDisabled ? apSystemPageInfo?.endCursor || null : pageInfo?.endCursor || null,
        fetchMore: allAccountsDisabled ? onFetchMoreApSystemFlatAccounts : fetchMore,
        paginationResultsPerPage,
      }),
    pageInfo: allAccountsDisabled ? apSystemPageInfo : pageInfo,
    refetchOrganisationFlatAccounts: refetch,
    refetchApSystemFlatAccounts,
    networkStatus: allAccountsDisabled ? apSystemNetworkStatus : networkStatus,
    onNextPage: () =>
      onNextPage({
        endCursor: allAccountsDisabled ? apSystemPageInfo?.endCursor || null : pageInfo?.endCursor || null,
        refetch: allAccountsDisabled ? refetchApSystemFlatAccounts : refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: allAccountsDisabled ? apSystemPageInfo?.startCursor || null : pageInfo?.startCursor || null,
        refetch: allAccountsDisabled ? refetchApSystemFlatAccounts : refetch,
        paginationResultsPerPage,
      }),
  }
}
